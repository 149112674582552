import React from "react";
import "./BookList.css";

import coverImg from "../../images/pict.jpg";
import { useGlobalContext } from "../../Context";
import Loader from "../Loader/Loader";
import Book from "./Book";

// https://covers.openlibrary.org/b/id/240727-S.jpg

const BookList = () => {
  const { books, loading, resultTitle } = useGlobalContext();
  const bookswithCovers = books.map((singleBook) => {
    return {
      ...singleBook,
      id: singleBook.id.replace("/works/", ""),
      cover_img: singleBook.cover_id
        ? `https://covers.openlibrary.org/b/id/${singleBook.cover_id}-L.jpg`
        : coverImg,
    };
  });

  console.log(bookswithCovers)

  if (loading) return <Loader />;

  return (
    <section className="booklist">
      <div className="container">
        <div className="section_title">
          <h2>{resultTitle}</h2>
        </div>
        <div className="booklist_content grid">
          {
            bookswithCovers.slice(0, 30).map((item, index) => {
              return (
                <Book key  = {index} {...item} /> 
              )
            })
          }
        </div>
      </div>
    </section>
  );
};

export default BookList;
