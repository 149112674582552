import React, { useEffect, useRef } from "react";
import "./SearchForm.css";
import { FaSearch } from "react-icons/fa";
import { useGlobalContext } from "../../Context";
import { useNavigate } from "react-router-dom";

const SearchForm = () => {
  const { setSearchTerm, setResultTitle } = useGlobalContext();
  const searchText = useRef("");
  const navigate = useNavigate();

  useEffect(() => searchText.current.focus(), []);
  const handleSubmit = (e) => {
    e.preventDefault();
    let tempSearchTerm = searchText.current.value.trim();
    if (tempSearchTerm.replace(/[^\w\s]/gi, "").length === 0) {
      setSearchTerm("The last year");
      setResultTitle("Please Enter Something ...");
    } else {
      setSearchTerm(searchText.current.value);
    }
    navigate("/book");
  };

  return (
    <div className="search_form">
      <div className="container">
        <div className="search_form_content">
          <form className="search_form" onSubmit={handleSubmit}>
            <div className="search_form_elem flex flex_sb bg_white">
              <input
                type="text"
                className="form_control"
                placeholder="The last year ..."
                ref={searchText}
              />
              <button
                type="submit"
                className="flex flex_c"
                onClick={handleSubmit}
              >
                <FaSearch className="text_purple" size={32} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
