import React, { useEffect, useState } from "react";
import "./BookDetails.css";
import coverImg from "../../images/pict.jpg";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import { FaArrowLeft } from "react-icons/fa";

const URL = "https://openlibrary.org/works/";

const BookDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [book, setBook] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    async function getBookDetails() {
      try {
        const response = await fetch(`${URL}${id}.json`);
        const data = await response.json();

        if (data) {
          const {
            description,
            title,
            covers,
            subject_places,
            subject_times,
            subjects,
          } = data;
          const newBook = {
            description: description
              ? description.value
              : "No discription found",
            title: title,
            cover_img: covers
              ? `https://covers.openlibrary.org/b/id/${covers[0]}-L.jpg`
              : coverImg,
            subject_places: subject_places
              ? subject_places.join(", ")
              : "No subject places found",
            subject_times: subject_times
              ? subject_times.join(", ")
              : "No subject times found",
            subjects: subjects ? subjects.join(", ") : "No subjects found",
          };
          setBook(newBook);
        } else {
          setBook(null);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    getBookDetails();
  }, [id]);

  if (loading) return <Loader />;

  return (
    <section className="book_details">
      <div className="container">
        <button
          type="button"
          className="flex flex_c back_btn"
          onClick={() => navigate("/book  ")}
        >
          <FaArrowLeft size={22} />
          <span className="fs_18 fw_6">Go Back</span>
        </button>

        <div className="book_details_content grid">
          <div className="book_details_img">
            <img src={book?.cover_img} alt="cover img" />
          </div>
          <div className="book_details_info">
            <div className="book_details_item title">
              <span className="fw_6 fs_24">{book?.title}</span>
            </div>
            <div className="book_details_item description">
              <span>{book?.description}</span>
            </div>
            <div className="book_details_item">
              <span className="fw_6">Subject Places</span>
              <span className="text_italic">{book?.subject_places}</span>
            </div>
            <div className="book_details_item">
              <span className="fw_6">Subject Times</span>
              <span className="text_italic">{book?.subject_times}</span>
            </div>
            <div className="book_details_item">
              <span className="fw_6">Subjects</span>
              <span className="text_italic">{book?.subjects}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookDetails;
