import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import logoimg from "../../images/logo.jpg";
import { HiOutlineMenuAlt3 } from "react-icons/hi";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const handleNavbar = () => setToggleMenu(!toggleMenu);

  return (
    <nav className="navbar" id="navbar">
      <div className="container navbar_content flex">
        <div className="brand_and_toggler  flex flex_sb">
          <Link to="/" className="navbar_brand flex">
            <img src={logoimg} alt="logo" />
            <span className="text_uppercase fw_7 fs_24 ls_1">ShelfSpy</span>
          </Link>
          <button
            type="button"
            className="navbar_toggler_btn"
            onClick={handleNavbar}
          >
            <HiOutlineMenuAlt3
              size={35}
              style={{
                color: `${toggleMenu ? "#fff" : "#010101"}`,
              }}
            />
          </button>
        </div>

        <div
          className={
            toggleMenu
              ? "navbar_collapse show_navbar_collapse"
              : "navbar_collapse"
          }
        >
          <ul className="navbar_nav">
            <li className="nav_item">
              <Link
                to="book"
                className="nav_link text_uppercase text_white fs_22 fw_6 ls_1"
              >
                Home
              </Link>
            </li>
            <li className="nav_item">
              <Link
                to="about"
                className="nav_link text_uppercase text_white fs_22 fw_6 ls_1"
              >
                About
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
