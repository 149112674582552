import React from 'react'
import './About.css'

const About = () => {
  return (
    <div>
      
    </div>
  )
}

export default About
