import React from "react";
import "./BookList.css";
import { Link } from "react-router-dom";

const Book = (book) => {
  return (
    <div className="book_item flex flex_column flex_sb">
      <div className="book_item_img">
        <img src={book.cover_img} alt="cover" />
      </div>
      <div className="book_item_info text_center">
        <Link to={`/book/${book.id}`} {...book}>
          <div className="book_item_info_item title fw_7 fs_18">
            <span>{book.title}</span>
          </div>
        </Link>

        <div className="book_item_info_item author fs-15">
          <span className="text_capitalize fw_7"> Author: </span>
          <span>{book.author ? book.author.join(", ") : "Unknown"}</span>
        </div>

        <div className="book_item_info_item author fs-15">
          <span className="text_capitalize fw_7"> Total Editions: </span>
          <span>{book.edition_count}</span>
        </div>

        <div className="book_item_info_item author fs-15">
          <span className="text_capitalize fw_7"> First Publish Year: </span>
          <span>{book.first_publish_year}</span>
        </div>
        
      </div>
    </div>
  );
};

export default Book;
