import React from 'react'
import './Loader.css'
import Loaderimg from '../../images/loader.jpg'

const Loader = () => {
  return (
    <div className='loader flex flex_c'>
      <img src={Loaderimg} alt="loader" />
    </div>
  )
}

export default Loader
