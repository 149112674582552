import React from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";
import SearchForm from "../SearchForm/SearchForm";

const Header = () => {
  return (
    <div className="header">
      <Navbar/>
        <div className="header_content flex flex_c text-center text_white">
          <h2 className="header_title text_capitalize">
            Find your book of your choice here
          </h2>{" "}
          <br />
          <p className="header_text fs_18 fw_3">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod
            facere nobis, unde blanditiis deleniti in! Alias rem ducimus maxime
            necessitatibus?
          </p>
          <SearchForm />
        </div>
    </div>
  );
};

export default Header;
